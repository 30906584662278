// ./lib/actions/simplifi/s3/getProfile.ts

import superjson from 'superjson';
import { join as pathJoin } from 'path';

import { AWS_S3_BUCKET } from '#/config/constants';
import { s3 } from '#/services/s3';
import { Profile } from '#/types/profile';
import { PATH_USERS } from '../filepaths';

export default async function getProfile(address: string): Promise<any> {
  const userHome = pathJoin(PATH_USERS, address);
  const profileKey = pathJoin(userHome, 'profile.json');

  const getObjectParams = {
    Bucket: AWS_S3_BUCKET,
    Key: profileKey,
  };

  try {
    const profileData = await s3.getObject(getObjectParams).promise();
    if (profileData.Body) {
      const profile: Profile = superjson.parse(
        typeof profileData?.Body !== 'string'
          ? profileData?.Body.toString()
          : profileData?.Body,
      );
      return profile;
    }
  } catch (error: any) {
    console.error(`Error getting profile file: ${error}`);
    throw new Error(`Error getting profile: ${error.message}`);
  }
}
