// #/services/s3/actions/putS3Object.ts

import { s3 } from '#/services/s3';
import S3 from 'aws-sdk/clients/s3';
import { throttle } from 'lodash';
import { isBuffer } from 'util';

// This part checks if the environment is Node.js
const isNode =
  typeof process !== 'undefined' &&
  process.versions != null &&
  process.versions.node != null;

// Stream and Buffer will only be imported in a Node.js environment
let Readable;
if (isNode) {
  Readable = require('stream').Readable;
}

/**
 * Converts Blob, ArrayBuffer, or Buffer to a stream. In the browser, this converts a Blob to a ReadableStream.
 * In Node.js, this converts a Buffer to a Readable stream.
 *
 * @param {Blob|ArrayBuffer|Buffer} data - The data to convert.
 * @return {ReadableStream|Readable} - The stream.
 */
function dataToStream(data: any) {
  if (isNode) {
    // Node.js environment
    if (isBuffer(data)) {
      const readable = new Readable();
      readable._read = () => { }; // _read is required but you can noop it
      readable.push(data);
      readable.push(null); // End of stream (EOF)
      return readable;
    } else {
      throw new Error('Data must be a Buffer in Node.js');
    }
  } else {
    // Browser environment
    if (data instanceof Blob) {
      return data.stream();
    } else {
      throw new Error('Data must be a Blob in the browser');
    }
  }
}

/**
 * Uploads an object to Amazon S3.
 *
 * @param {Object} putObjectParams - Parameters for the putObject operation.
 * @returns {Promise<Object>} - A promise that resolves with the result of the putObject operation.
 */
export async function putS3Object(putObjectParams: S3.Types.PutObjectRequest) {
  try {
    let Body = putObjectParams.Body;
    // if (Body instanceof Blob) {
    //   Body = Body.stream();
    // }
    // else if (isBuffer(Body)) {
    //   Body = dataToStream(Body);
    // }
    // else if (ArrayBuffer.isView(Body)) {
    //   // Convert ArrayBuffer to Buffer
    //   Body = Buffer.from(Body.buffer);
    // }
    return await s3.putObject({ ...putObjectParams, Body }).promise();
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error putting S3 object: ${error}`);
  }
}

export const putS3ObjectThrottled = throttle(putS3Object, 300);

export default putS3Object;
