// #/services/s3/actions/createOrRetrieveUserFolder.ts

import superjson from 'superjson';
import { join as pathJoin } from 'path';

import { AWS_S3_BUCKET } from '#/config/constants';
import { Profile } from '#/types/profile';

import Simplifi_S3 from '.';
import { s3 } from '..';
import { PATH_USERS } from '../filepaths';

export default async function createOrRetrieveUserFolder(
  address: string,
): Promise<boolean> {
  const userHome = pathJoin(PATH_USERS, address);
  const userWorkspacesFolderKey = pathJoin(userHome, 'workspaces/');
  const userModulesFolderKey = pathJoin(userHome, 'modules/');
  const profileKey = pathJoin(userHome, 'profile.json');

  try {
    await s3.headObject({ Bucket: AWS_S3_BUCKET, Key: userHome }).promise();
    return true;
  } catch (err: any) {
    if (err.code === 'NotFound') {
      const putObjectParams = [
        { Key: userWorkspacesFolderKey, Body: '' },
        { Key: userModulesFolderKey, Body: '' },
      ];

      await Promise.all(
        putObjectParams.map((params) => {
          return s3.putObject({ Bucket: AWS_S3_BUCKET, ...params }).promise();
        }),
      );

      const defaultProfileExists = await Simplifi_S3.checkFileExists({
        s3ObjectKey: profileKey,
      });

      if (!defaultProfileExists) {
        const defaultProfile: Profile = Simplifi_S3.getDefaultProfile(address);
        const putProfileParams = {
          Bucket: AWS_S3_BUCKET,
          Key: profileKey,
          Body: superjson.stringify(defaultProfile),
        };

        await s3.putObject(putProfileParams).promise();
      }

      return true;
    } else {
      console.error(`Error creating/retrieving user folder: ${err}`);
      return false;
    }
  }
}
/*
SSS
W
ORM
*/
